<template>
	<div class="myPointsContent" :class="themeClass">
		<div class="rightContent width-fill">
			<div class="myPointsContent-title">我的积分</div>
			<div class="card flex-row-space-between">
				<div class="flex-left">
					<div class="all">总积分</div>
					<div class="num">{{ sumCustomerPoint }}</div>
				</div>
				<div class="flex-right">
					<div class="btn pointer" @click="pointsBill">积分账单</div>
					<div class="btn pointer" @click="goExchange">兑换金币</div>
				</div>
			</div>
			<p class="storRow">店铺({{ count }})</p>
			<div class="shopPoints margin-r-6 pointer">
				<div class="content" v-for="(item, index) of listData" :key="index" @click="pointsBill">
					<div class="shop_left">
						<div class="shop_pic"><img width="40px" height="40px" :src="item.fShopLogoPath == '' ? imgurl : item.fShopLogoPath" /></div>
						<div>
							<div style="width:220px;" class="company">{{ item.fShopName }}</div>
							<div class="see">查看积分明细</div>
						</div>
					</div>
					<div class="shop_right">{{ item.fEndBalance }}分</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			imgurl: require('@/assets/shoplogo.png'),
			sumCustomerPoint: '',
			count: '',
			allPoints: '325',
			// storPoints:8,
			listData: []
		};
	},
	computed: {
		...mapGetters(['getThemeName']),
		themeClass() {
			return `theme-${this.getThemeName}`;
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			this.ApiRequestPost('api/mall/ebbalance/customer-point/get-my-point', {}).then(
				result => {
					console.log('result', result);
					this.listData = result.obj.pointlist;
					this.sumCustomerPoint = result.obj.sumCustomerPoint;
					this.count = result.obj.count;
				},
				rej => {}
			);
		},
		pointsBill() {
			this.$router.push({
				path: '/PersonalContent/pointsBill',
				query: ''
			});
		},
		goExchange() {
			this.$router.push({
				path: '/goldMall/ExchangeG',
				query: ''
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.color-theme {
	@include themify($themes) {
		color: themed('themes_color');
	}
}
.myPointsContent-title {
	font-weight: bold;
	color: #000000;
	font-size: 18px;
	margin: 13px 10px;
}
.myPointsContent {
	min-height: 485px;
	display: flex;
	margin: 10px auto;
	justify-content: flex-end;
	background-color: #ffffff;
	padding: 0px 13px;
}
.rightContent {
	/* width: 916px; */
}
.flex-row-space-between {
	display: flex;
	flex-direction: row; /* 主轴方向*/
	flex-wrap: nowrap; /* 是否换行*/
	justify-content: space-between; /* 水平主轴对齐方式*/
	align-items: center; /* 垂直对齐方式*/
}
.card {
	width: 100%;
	height: 121px;
	background: url(../../../assets/bgimg/points.png) no-repeat 0 40px, linear-gradient(50deg, #9870f5 0%, #c793e4 30%, #f894c6 70%, #fbaad2 95%);
	background-size: contain;
	background-repeat: space;
	text-align: center;
	border-radius: 20px;
	padding: 0px 62px 0px 30px;
	box-sizing: border-box;
}
.flex-left {
	text-align: left;
	margin-top: 24px;
}
.all {
	height: 16px;
	line-height: 16px;
	font-size: 14px;
	color: #ffffff;
	margin: 0 auto;
}
.num {
	height: 42px;
	line-height: 42px;
	font-size: 36px;
	font-weight: bold;
	color: #ffffff;
	margin: 15px auto 15px auto;
}
.flex-right {
}
.btn {
	display: inline-block;
	width: 131px;
	height: 31px;
	font-size: 16px;
	line-height: 31px;
	background: rgba(255, 255, 255, 0.3);
	border-radius: 30px;
	color: #ffffff;
	font-family: 'Times New Roman', -400;
	margin-left: 35px;
}
.storRow {
	margin: 17px 0px;
	font-size: 16px;
	font-weight: bold;
}

.shopPoints {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
}
.content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(50% - 9px);
	width: -webkit-calc(50% - 9px);
	width: -moz-calc(50% - 9px);
	margin-bottom: 10px;
	padding: 13px 14px;
	padding-right: 26px;
	background: #f8f7fe;
	border-radius: 10px;
	height: 65px;
	background: #ffffff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.149019607843137);
	box-sizing: border-box;
}

.shop_left {
	display: flex;
}

.shop_pic {
	width: 40px;
	height: 40px;
	background: #ffffff;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	padding: 4px;
	margin-right: 13px;
}
.company {
	width: 300px;
	height: 16px;
	line-height: 16px;
	font-size: 14px;
	font-weight: bold;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.see {
	height: 16px;
	line-height: 16px;
	color: #999;
	font-size: 13px;
	margin-top: 10px;
}

.shop_right {
	line-height: 17px;
	height: 17px;
	font-size: 15px;
	font-weight: bold;
	color: #ff0000;
}
</style>
